import { useCallback, useEffect, useRef, useState } from 'react'
import { Subscription } from 'rxjs'

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { gtmEvents } from '../enums'
import { useServices } from './use-services-context'

interface IReloadRequiredProps {
  isReloadRequired: boolean
}
export const useAppReloadRequired = (): IReloadRequiredProps => {
  const sendDataToGTM = useGTMDispatch()
  const { appService } = useServices()
  const appVersionSubscriptionRef = useRef<Subscription | null>(null)
  const intervalTime = 10 * 60 * 1000
  const [isReloadRequired, setIsReloadRequired] = useState(false)

  const trackCallError = useCallback((): void => {
    sendDataToGTM({
      eventName: gtmEvents.CallingNewBuildNumberApi,
      message: 'error in calling build number from app-metadata',
    })
  }, [sendDataToGTM])

  useEffect(() => {
    if (appVersionSubscriptionRef.current || !appService) return
    appVersionSubscriptionRef.current = appService
      .getAppVersion(intervalTime, trackCallError)
      .subscribe({
        next: (newAppVersion) => {
          let reload = false
          const isReloadModalTriggred =
            sessionStorage.getItem('isReloadModalTriggred') || false
          const currentAppVersion = process.env.REACT_APP_BUILD_NUMBER
          // the two folowing console is for debugging purpose only. will be removed after check in development.
          console.log('newBuildNumber:', newAppVersion) // eslint-disable-line
          console.log('currentBuildNumber:', currentAppVersion) // eslint-disable-line
          if (
            newAppVersion &&
            currentAppVersion &&
            currentAppVersion !== newAppVersion &&
            !isReloadModalTriggred
          ) {
            reload = true
          }
          if (!isReloadModalTriggred) setIsReloadRequired(reload)
        },
      })
  }, [intervalTime, trackCallError, appService])
  useEffect(() => {
    return () => {
      if (appVersionSubscriptionRef?.current) {
        appVersionSubscriptionRef.current.unsubscribe()
        appVersionSubscriptionRef.current = null
      }
    }
  }, [])

  return { isReloadRequired }
}
