import { ImageExtensions } from '../../../enums'
import { IClientSettings } from '../../../models'

export const VanderHouwenSettings: IClientSettings = {
  common: {
    name: 'VanderHouwen',
    logo: {
      imgExtension: ImageExtensions.PNG,
    },
  },
}
