import { IClientSettings } from '@common/models'

import { ImageExtensions } from '../../../enums'

export const CompustaffSettings: IClientSettings = {
  common: {
    name: 'Compustaff',
    logo: {
      imgExtension: ImageExtensions.PNG,
    },
  },
}
