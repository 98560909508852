import { FC, useMemo } from 'react'
import { useObservableEagerState } from 'observable-hooks'

import { Alert, AlertProps, useTheme } from '@mui/material'
import { PaletteModeCode, themeService } from '@procom-labs/common'

import { WithGorillaInnerTheme } from './with-inner-theme'

const WithGorillaAlert = WithGorillaInnerTheme<AlertProps>(Alert)

export const GorillaAlert: FC<AlertProps> = (props) => {
  const theme = useTheme()
  const currentTheme = useObservableEagerState(themeService.baseThemeMode$)

  const themeMode = useMemo(() => {
    return currentTheme === PaletteModeCode.light
      ? currentTheme
      : (theme.palette.mode as PaletteModeCode)
  }, [currentTheme, theme.palette.mode])

  return (
    <WithGorillaAlert
      themeMode={themeMode}
      {...props}
      sx={{
        ...props?.sx,
        border:
          currentTheme === PaletteModeCode.light ? '1px solid' : undefined,
      }}
    />
  )
}
