import React, { forwardRef, useMemo } from 'react'
import { useObservableState } from 'observable-hooks'

import { ThemeProvider, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  clientService,
  deepMergeObjects,
  PaletteModeCode,
  ThemeConfig,
  themeService,
  useIsGorillaTheme,
} from '@procom-labs/common'

export interface InnerThemeProps {
  isDarkMode?: boolean
  isLightMode?: boolean
  isGlassMode?: boolean
  forceCreateTheme?: boolean
  isThemeBackgroundApplied?: boolean
  themeMode?: PaletteModeCode
}

const excludedProps = [
  'isDarkMode',
  'isLightMode',
  'isGlassMode',
  'isThemeBackgroundApplied',
  'forceCreateTheme',
  'themeMode',
]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function WithGorillaInnerTheme<T extends object>(
  Component: React.FC<T & InnerThemeProps>,
  themeConfig?: ThemeConfig,
  defaultProps?: Partial<T>
) {
  const StyledComponent = styled(Component, {
    shouldForwardProp: (prop) => !excludedProps.includes(prop as string),
  })<{ isThemeBackgroundApplied?: boolean }>(
    ({ theme, isThemeBackgroundApplied }) => {
      let styles = {}
      if (isThemeBackgroundApplied) {
        styles = {
          color: theme.palette.text.primary,
          background: theme.palette.background.default,
        }
      }
      return styles
    }
  )

  return forwardRef<HTMLElement, T & InnerThemeProps>(
    (props: T & InnerThemeProps, ref) => {
      const theme = useTheme()
      const clientSettings = useObservableState(
        clientService.clientSetting$,
        null
      )
      const isGorillaTheme = useIsGorillaTheme()

      const paletteModeCode: PaletteModeCode | null = useMemo(() => {
        if (props?.themeMode) {
          return props?.themeMode
        }
        if (props?.isDarkMode) {
          return PaletteModeCode.dark
        }
        if (props?.isGlassMode) {
          return PaletteModeCode.glass
        }
        if (props?.isLightMode) {
          return PaletteModeCode.light
        }
        return null
      }, [
        props?.themeMode,
        props?.isDarkMode,
        props?.isLightMode,
        props?.isGlassMode,
      ])

      const isModeChanged = paletteModeCode
        ? paletteModeCode !== theme.palette.mode
        : false

      const innerTheme = React.useMemo(() => {
        if (
          isModeChanged &&
          paletteModeCode &&
          clientSettings &&
          isGorillaTheme
        ) {
          return themeService.getOrCreateTheme(
            paletteModeCode,
            clientSettings,
            props?.forceCreateTheme,
            themeConfig
          )
        }
        return theme
      }, [
        clientSettings,
        isModeChanged,
        theme,
        paletteModeCode,
        props?.forceCreateTheme,
        isGorillaTheme,
      ])

      const componentProps = useMemo(() => {
        if (defaultProps) {
          return deepMergeObjects(defaultProps, props)
        }
        return props
      }, [props])
      return (
        <ThemeProvider theme={innerTheme}>
          <StyledComponent {...componentProps} ref={ref} />
        </ThemeProvider>
      )
    }
  )
}
