import { LoginTypes } from '../enums/portal'

export enum LocalStorageAttributes {
  IsResumeCopilotConsentGiven = 'isResumeCopilotConsentGiven',
}

const LoginTypeAllowedAttributes = {
  Contractor: ['isResumeCopilotConsentGiven'],
  Client: [''],
  Employer: [''],
  Customer: [''],
  Support: [''],
}

const isAttributeExists = (attribute: LocalStorageAttributes): boolean => {
  const loginType = localStorage.getItem('loginType') as LoginTypes

  return LoginTypeAllowedAttributes[loginType]?.includes(attribute)
}

export const setProtectedLocalStorageAttribute = (
  attribute: LocalStorageAttributes,
  value: string
): void => {
  if (isAttributeExists(attribute)) {
    localStorage.setItem(attribute, value)
  }
}

export const removeProtectedLocalStorageAttribute = (
  attribute: LocalStorageAttributes
): void => {
  if (isAttributeExists(attribute)) {
    localStorage.removeItem(attribute)
  }
}
