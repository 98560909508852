import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
  getVendorCode,
  useIsClientHCMWorks,
  VendorCodes,
} from '@procom-labs/common'
import {
  AlertContextProvider,
  NewAppVersionPrompt,
} from '@procom-labs/molecules'

import { PageNotFound } from '@auth-portal/components'
import { LoginDigitCode } from '@auth-portal/routes/login-digit-code'

import ActivateFboInvitationRoute from './activate-fbo-invitation-route'
import ChangePasswordRoute from './change-password-route'
import CompleteRegistration from './complete-registration'
import { ExpiredPasswordRoute } from './expired-password-route'
import InitialRoute from './initial-route'
import Login from './login'
import { LoginMfa } from './login-mfa'
import Logout from './logout'
import PrivacyPolicy from './privacy-policy'
import ResetPassword from './reset-password'
import SignInLinkedinCallback from './signin-linkedin-callback'
import SignInSocialCallback from './signin-social-callback'
import ContractorSignup from './signup'
import ContractorSignupWithEmail from './signup-with-email'
import TermsOfUse from './terms-of-use'
import VerifyEmailRoute from './verify-email-route'

export const AppRoutes: FC = () => {
  const isClientHCMWorks = useIsClientHCMWorks()

  return (
    <AlertContextProvider>
      <NewAppVersionPrompt />
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login-mfa" element={<LoginMfa />} />
        <Route path="/login-digit-code" element={<LoginDigitCode />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePasswordRoute />} />
        <Route
          path="/reset-expired-password"
          element={<ExpiredPasswordRoute />}
        />
        <Route
          path="/complete-registration"
          element={<CompleteRegistration />}
        />
        <Route path="/initial-auth-route" element={<InitialRoute />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/verify-email" element={<VerifyEmailRoute />} />
        <Route
          path="/signup"
          element={
            !isClientHCMWorks ? <ContractorSignup /> : <Navigate to="/" />
          }
        />
        <Route
          path="/signup-email"
          element={
            !isClientHCMWorks &&
            [VendorCodes.PCGL].includes(getVendorCode()) ? (
              <ContractorSignupWithEmail />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/signin-social-callback"
          element={<SignInSocialCallback />}
        />
        <Route
          path="/activate-fbo-invitation"
          element={<ActivateFboInvitationRoute />}
        />
        <Route
          path="/signin-linkedin-callback"
          element={<SignInLinkedinCallback />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AlertContextProvider>
  )
}
