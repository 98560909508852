import React from 'react'
import { Box, CircularProgress, SxProps, Theme } from '@mui/material'
import styled from '@emotion/styled'

type PreloaderProps = {
  center?: boolean
  height?: string
  sx?: SxProps<Theme>
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'center' && prop !== 'height',
})<PreloaderProps>(({ center, height }) => ({
  ...(center && {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: height ?? '100vh',
    minHeight: 400,
  }),
}))

export const Preloader: React.FC<PreloaderProps> = ({
  center,
  height,
  sx,
}: PreloaderProps) => (
  <StyledBox
    center={center}
    height={height}
    className="c-preloader"
    sx={{ ...sx }}
  >
    <CircularProgress variant="indeterminate" />
  </StyledBox>
)

Preloader.defaultProps = {
  center: false,
}
