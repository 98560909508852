import { useMemo } from 'react'
import { useObservableState } from 'observable-hooks'
import { AppTypes } from '../enums'

import { clientService } from '../services'

export const useIsGorillaTheme = (): boolean => {
  const appType = useObservableState(clientService.appType$, null)

  return useMemo(() => {
    return appType === AppTypes.auth || appType === AppTypes.client
  }, [appType])
}
