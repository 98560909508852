import { AwsErrors } from '../config/aws-errors'

class AwsService {
  translateAWSErrors(error: string, i18n: any, context?: string): string {
    const awsError = AwsErrors.find(
      (ae) => ae.errorCode === error && ae.context === context
    )
    if (awsError) {
      return i18n.t(awsError.errorMessage)
    }
    return i18n.t('common.alert.somethingWrong')
  }
}

export const awsService = new AwsService()
