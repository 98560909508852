import { TFunction } from 'react-i18next'

import { Theme } from '@mui/material'

import { BlobTypes } from '../enums'
import { IParsedProfileData, SortDataType } from '../interfaces'
import {
  DragStatus,
  IAzureCandidate,
  ILocation,
  SortOrder,
  Soverign,
} from '../models'

export const downloadFile = (
  data: ArrayBuffer,
  name: string,
  openNewTab: boolean,
  mimeType: string
): void => {
  const blob = new Blob([data], { type: mimeType })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  if (openNewTab) {
    link.target = '_blank'
  } else {
    link.download = name
  }
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export const downloadPDF = (
  data: ArrayBuffer,
  name: string,
  openNewTab = false
): void => downloadFile(data, name, openNewTab, BlobTypes.Pdf)

export const downloadCSV = (
  data: ArrayBuffer,
  name: string,
  openNewTab = false
): void => downloadFile(data, name, openNewTab, BlobTypes.Csv)

export const downloadDocx = (
  data: ArrayBuffer,
  name: string,
  openNewTab = false
): void => downloadFile(data, name, openNewTab, BlobTypes.Docx)

export const deepMergeObjects = (obj1: any, obj2: any): any => {
  const result = { ...obj1 }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(obj2)) {
    if (
      obj1[key] &&
      obj2[key] &&
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object'
    ) {
      const obj = deepMergeObjects(obj1[key], obj2[key])
      if (Array.isArray(obj1[key]) || Array.isArray(obj2[key])) {
        result[key] = Object.values(obj)
      } else {
        result[key] = obj
      }
    } else {
      result[key] = obj2[key]
    }
  }
  return result
}

export const paginationLabelDisplayedRows =
  (t: TFunction) =>
  ({ from, to, count }: { from: number; to: number; count: number }) => {
    return `${from}–${to} ${t('common.preps.of')} ${
      count !== -1 ? count : `${t('contractorJobs.tabs.moreThan')} ${to}`
    }`
  }

export const byteToMegabyte = (fileSize: number): string | number => {
  if (fileSize) {
    return (fileSize / 1024 ** 2).toFixed(3)
  }
  return 0
}

export const MAX_WORKPLACE_TYPE_CODES = 3

export const REGENERATE_LIMIT = 5

export const MAX_FILE_UPLOAD_UNIT = 1000000

export const concatAndFormatAiOutput = (
  prevValue: string,
  message: string | null
): string => {
  const formattedResult = message
    ? message.toString().replace(/\n/g, '<br>')
    : ''
  if (formattedResult) {
    return prevValue + formattedResult
  }
  return prevValue
}

export const concatAndFormatTipsOutput = (
  prevValue: string,
  message: string | null
): string => {
  const formattedResult = message ? message.toString().replace(/\n/g, '') : ''
  if (formattedResult) {
    return prevValue + formattedResult
  }
  return prevValue
}

export const concatAndFormatAIOutputWithExtraSpace = (
  prevValue: string,
  message: string | null
): string => {
  const formattedResult = message
    ? message.toString().replace(/\n\n/g, '<br><br>')
    : ''
  if (formattedResult) {
    const result = formattedResult.replace(/\n/g, '<br><br>')
    return `${prevValue + result}`
  }
  return prevValue
}

const charArray = [
  '[',
  '\\',
  '&',
  '/',
  '|',
  '!',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '^',
  '"',
  '~',
  '*',
  '?',
  ':',
  ']',
]

export const getParsedProfileData = (
  data: Soverign.ParsedResume | undefined
): IParsedProfileData => {
  return {
    firstName: data?.contactInformation?.candidateName?.givenName || undefined,
    lastName: data?.contactInformation?.candidateName?.familyName || undefined,
    email: data?.contactInformation?.emailAddresses?.length
      ? data.contactInformation.emailAddresses[0]
      : undefined,
    phone: data?.contactInformation?.telephones?.length
      ? `${data?.contactInformation.telephones[0].areaCityCode || ''}${
          data?.contactInformation.telephones[0].subscriberNumber || ''
        }`.replace(/\D+/g, '')
      : undefined,
    address:
      data?.contactInformation?.location?.streetAddressLines || undefined,
    city: data?.contactInformation?.location?.municipality || undefined,
    countryCode: data?.contactInformation?.location?.countryCode || undefined,
    zip: data?.contactInformation?.location?.postalCode || undefined,
  }
}

export const getCurrentDate = (): string => {
  return new Date().toISOString()
}

export const stringToBoolean = (val: string | boolean | undefined): boolean =>
  val === 'false' ? false : !!val

export const getProperty = (obj: any, propNested: string[]): string => {
  if (!obj || !propNested) {
    return ''
  }
  if (propNested.length === 1) {
    const key = propNested[0]
    return obj[key]
  }
  const newObj = propNested.shift()
  return getProperty(obj[newObj as string], propNested)
}

export const comparator = (a: string, b: string): number => {
  if (!a) {
    if (!b) {
      return 0
    }
    return 1
  }

  if (!b) {
    return -1
  }

  if (typeof a === 'boolean' || typeof b === 'boolean') {
    return a.toString().localeCompare(b.toString())
  }

  return a.localeCompare(b)
}

export const stringComparator = (a: string, b: string): number =>
  comparator(a.toLowerCase(), b.toLowerCase())

export const getComparator = (
  a: any,
  b: any,
  sortType: SortDataType
): number => {
  const aProp = getProperty(a, sortType.field.split('.'))
  const bProp = getProperty(b, sortType.field.split('.'))
  return sortType.order === SortOrder.desc
    ? -comparator(aProp, bProp)
    : comparator(aProp, bProp)
}

export const transform = (val: string, characters = charArray): string => {
  let str = val
  characters.forEach((char: string) => {
    // Escape special characters before using them in the regular expression
    const escapedChar = char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    if (str.includes(char)) {
      str = str.replace(new RegExp(escapedChar, 'g'), `\\${char}`)
    }
  })
  return str
}

export const isValidDate = (date: string): boolean => {
  if (date) {
    const minDate = new Date(0)
    const currentDate = new Date(date)
    if (currentDate.getTime() > minDate.getTime()) {
      return true
    }
  }
  return false
}

export const convertStringToFloat = (rate: string): number => {
  return +parseFloat(rate.replace(/,/g, '')).toFixed(
    rate.split('.')[1]?.length || 0
  )
}

export const updateAddressWithStateIsoCode = (
  address: ILocation
): ILocation => {
  if (!address) return address
  return {
    ...address,
    stateIsoCode:
      address.countryCode && address.stateCode
        ? `${address.countryCode}-${address.stateCode}`
        : null,
  }
}

export const includesWithLowerCase = (
  property: string | null,
  searchStr: string
): boolean => {
  return (property ?? '').toLowerCase().includes(searchStr)
}

export const getUniqueCandidates = (
  candidates: IAzureCandidate[]
): IAzureCandidate[] => {
  return candidates?.reduce((accumulator: IAzureCandidate[], current) => {
    if (!accumulator.find((obj: IAzureCandidate) => obj.id === current.id)) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}

export const shallowCompare = <
  T extends Record<any, any>,
  R extends Record<any, any>
>(
  obj1: T,
  obj2: R
): boolean =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    // eslint-disable-next-line no-prototype-builtins
    (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
  )

export const getPlainText = (text: string): string => {
  return text?.replace(/<[^>]*>|&nbsp;/g, '')
}

export const getDropzoneColor = (
  props: { theme: Theme; isGorillaTheme?: boolean } & DragStatus
): string => {
  if (props.isFocused) {
    return '#008BCC'
  }
  if (props.isDragAccept) {
    return props.theme.palette.success.main
  }
  if (props.isDragReject) {
    return props.theme.palette.error.main
  }
  return props.isGorillaTheme ? props.theme.palette.primary.main : '#0000003b'
}

interface GroupedData {
  [key: string]: any[]
}

export const groupBy = (array: any[], key: string): GroupedData => {
  return array?.reduce((acc: GroupedData, obj) => {
    const keyValue = obj[key]

    if (!acc[keyValue]) {
      acc[keyValue] = []
    }

    acc[keyValue]?.push(obj)
    return acc
  }, {})
}

export const replaceOperators = (inputString: string): string => {
  const replacedSentence = inputString.replace(
    /("[^"]*"|\band\b|\bor\b)/g,
    (match: string) => {
      if (match.startsWith('"') && match.endsWith('"')) {
        return match // Keep double quoted strings intact
      }
      return match === 'and' ? '+' : '|'
    }
  )
  return replacedSentence
}

export const regexToExtractCode = /"code": "([^"]+)"/

export const SKIP_SURVEY_DURATION = 182
