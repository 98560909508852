import { IAwsError } from '../models/aws-error'
import { AwsErrorCodes } from '../enums/awsErrorCodes'

export const AwsErrors: Array<IAwsError> = [
  {
    errorCode: AwsErrorCodes.NotAuthorizedException,
    context: 'complete-registration',
    errorMessage: 'common.cognitoErrors.inviteExpired',
  },
  {
    errorCode: AwsErrorCodes.LimitExceededException,
    errorMessage: 'common.cognitoErrors.tooManyAttempts',
  },
  {
    errorCode: AwsErrorCodes.NotAuthorizedException,
    errorMessage: 'common.cognitoErrors.wrongCredentials',
  },
  {
    errorCode: AwsErrorCodes.PasswordResetRequiredException,
    errorMessage: 'common.cognitoErrors.pwtResetRequired',
  },
  {
    errorCode: AwsErrorCodes.ExpiredCodeException,
    context: 'change-password',
    errorMessage: 'common.cognitoErrors.changePasswordCodeExpired',
  },
  {
    errorCode: AwsErrorCodes.TooManyRequestsException,
    errorMessage: 'common.cognitoErrors.tooManyAttempts',
  },
  {
    errorCode: AwsErrorCodes.InvalidPasswordException,
    errorMessage: 'common.cognitoErrors.invalidPassword',
  },
  {
    errorCode: AwsErrorCodes.UserNotFoundException,
    errorMessage: 'common.cognitoErrors.userNotFound',
  },
  {
    errorCode: AwsErrorCodes.UsernameExistsException,
    errorMessage: 'common.cognitoErrors.usernameExists',
  },
]
