import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Footer: React.FC<{
  environment: any
  showLinks?: boolean
}> = ({ environment, showLinks = true }) => {
  const { t } = useTranslation('main')

  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        margin: 0,
        height: { xs: 'fit-content', md: 54 },
        paddingBottom: 0,
        flexDirection: { xs: 'column', md: 'row' },
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        columnGap={1}
        sx={{
          marginLeft: { md: 9, xl: 12 },
          marginBlock: { md: 2 },
        }}
      >
        {showLinks && (
          <>
            <Link href="/terms-of-use" target="_blank" variant="caption">
              {t('common.footer.termsOfUse')}
            </Link>
            <Typography
              display="inline"
              variant="h5"
              sx={{
                marginTop: '2px',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              {'\u2022'}
            </Typography>
            <Link href="/privacy-policy" target="_blank" variant="caption">
              {t('common.footer.privacyPolicy')}
            </Link>
          </>
        )}
      </Box>
      <Box
        sx={{
          marginRight: { md: 9 },
          marginBlock: { md: 9 },
          paddingBottom: { xs: 2, md: 0 },
        }}
        display="flex"
        alignItems="center"
        columnGap={1}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
          variant="caption"
        >
          {t('common.footer.poweredBy')}
        </Typography>
        <img
          src={`${environment.BLOB_STORAGE_URL}/procom-portal-assets/logos/client-connection.png`}
          alt="Client Connection"
        />
      </Box>
    </Box>
  )
}
