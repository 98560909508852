import { IClientSettings } from '@common/models'

import { ImageExtensions } from '../../../enums'

export const AplinSettings: IClientSettings = {
  common: {
    name: 'Aplin',
    logo: {
      imgExtension: ImageExtensions.PNG,
    },
  },
}
