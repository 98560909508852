import { IClientSettings } from '@common/models/clients-settings'

import { PaletteModeCode } from '../../../enums'

export const ProcomSettings: IClientSettings = {
  common: {
    name: 'Procom',
    effectiveDate: 'March 2017',
  },
  defaultThemeMode: {
    guestClient: PaletteModeCode.light,
    guestAuth: PaletteModeCode.light,
  },
}
