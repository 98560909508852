import { ClientCodes, ClientNames, VendorCodes } from '../enums'
import { getClientCode, getClientName, getClientVendorCode } from '../util'
import { useIsHubSite } from './use-is-hub-site'

const useIsClientByClientName = (clientNameToMatch: ClientNames): boolean => {
  return getClientName() === clientNameToMatch
}

const useIsClientByClientCode = (clientCodeToMatch: ClientCodes): boolean => {
  return getClientCode() === clientCodeToMatch
}

const useIsClientByVendorCode = (vendorCode: VendorCodes): boolean =>
  getClientCode() === null && getClientVendorCode() === vendorCode

export const useIsClientKpmg = (): boolean =>
  useIsClientByClientCode(ClientCodes.KPMG)

export const useIsClientCenovus = (): boolean =>
  useIsClientByClientCode(ClientCodes.CENOVUS)

export const useIsClientBMO = (): boolean =>
  useIsClientByClientCode(ClientCodes.BMO)

export const useIsClientProcom = (): boolean =>
  useIsHubSite() && getClientVendorCode() === VendorCodes.PCGL

export const useIsClientDemoCorp = (): boolean =>
  useIsClientByClientCode(ClientCodes.DemoCorp)

export const useIsClientProcomTeam = (): boolean =>
  useIsClientByClientCode(ClientCodes.ProcomTeam)

export const useIsClientHCMWorks = (): boolean =>
  useIsClientByClientCode(ClientCodes.HCMWorks)

export const useIsClientFlexTrack = (): boolean =>
  useIsClientByClientCode(ClientCodes.Flextrack)

export const useIsGorillaWorks = (): boolean =>
  useIsClientByClientName(ClientNames.GorillaWorks)

export const useIsVendorSymbiotic = (): boolean =>
  useIsClientByVendorCode(VendorCodes.Symbiotic)

export const useIsVendorAplin = (): boolean =>
  useIsClientByVendorCode(VendorCodes.APLN)

export const useIsVendorCompustaff = (): boolean =>
  useIsClientByVendorCode(VendorCodes.CMPSTF)

export const useIsVendorSoftChoice = (): boolean =>
  useIsClientByVendorCode(VendorCodes.SFTCHE)

export const useIsVendorVanderHouwen = (): boolean =>
  useIsClientByVendorCode(VendorCodes.VNDRHWN)
