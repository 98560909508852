import { useMemo } from 'react'
import { Observable } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { AuthProfileType, mapVendorCodeToName } from '..'
import { useServices } from './use-services-context'

export const useCommonTrackData = (): {
  userId: string | undefined
  vendorCode: string | undefined
  vendorName: string | undefined
  profileType: AuthProfileType | undefined
  clientName: string | null | undefined
  clientCode: string | undefined
} => {
  const { authService } = useServices()
  // created new Observable to void undefined error (Cannot read properties of undefined (reading 'subscribe'))
  // As when this hook is used in auth and submission portal, we don't have authService defined
  const currentUser$ = authService?.currentUser$ || new Observable()
  const currentUserProfile$ =
    authService?.currentUserProfile$ || new Observable()

  const currentUser = useObservable(() => currentUser$)
  const currentUserProfile = useObservable(() => currentUserProfile$)

  return useMemo(() => {
    return {
      userId: currentUser?.id ?? '',
      vendorCode: currentUserProfile?.vendorCode ?? '',
      vendorName: mapVendorCodeToName(currentUserProfile?.vendorCode) ?? '',
      profileType: currentUserProfile?.profileType ?? undefined,
      clientName: currentUserProfile?.clientName ?? '',
      clientCode: currentUserProfile?.clientCode ?? '',
      $page_title: document.title ?? '',
    }
  }, [
    currentUser?.id,
    currentUserProfile?.clientCode,
    currentUserProfile?.clientName,
    currentUserProfile?.profileType,
    currentUserProfile?.vendorCode,
    document.title,
  ])
}
