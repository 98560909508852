import {
  ClientCodes,
  ClientDomainType,
  ClientNames,
  VendorCodes,
} from '../enums'
import { IClientPortal } from '../models'
import {
  AplinSettings,
  BmoSettings,
  CenovusSettings,
  CompustaffSettings,
  DemoCorpSettings,
  EastWestSettings,
  FlextrackSettings,
  GorillaWorksSettings,
  HcmWorksSettings,
  KpmgSettings,
  ProcomSettings,
  ProcomTeamSettings,
  SoftchoiceSettings,
  SymbioticSettings,
  VanderHouwenSettings,
} from './clients-settings'

export const SiteList: IClientPortal[] = [
  {
    name: ClientNames.Procom,
    vendorCode: VendorCodes.PCGL,
    code: null,
    domains: [
      {
        hostname: 'portal.procomservices.com',
        authHostname: 'login.procomservices.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://login.procomservices.com',
        homepageAnonymousUsersUrl: 'https://portal.procomservices.com/jobs',
      },
      {
        hostname: 'procom-portal-dev.clientconnections.com',
        authHostname: 'procom-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://procom-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'procom-portal-uat.clientconnections.com',
        authHostname: 'procom-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://procom-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'localhost',
        authHostname: 'localhost',
        domainType: ClientDomainType.Local,
        authPortalUrl: 'http://localhost:8080',
        homepageAnonymousUsersUrl: null,
      },
    ],
    settings: ProcomSettings,
  },
  {
    name: ClientNames.BMO,
    code: ClientCodes.BMO,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'bmo-portal.clientconnections.com',
        authHostname: 'bmo-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://bmo-login.clientconnections.com',
        homepageAnonymousUsersUrl: 'https://bmo.clientconnections.com',
      },
      {
        hostname: 'bmo-portal-dev.clientconnections.com',
        authHostname: 'bmo-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://bmo-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'bmo-portal-qa.clientconnections.com',
        authHostname: 'bmo-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://bmo-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'bmo-portal-uat.clientconnections.com',
        authHostname: 'bmo-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://bmo-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: BmoSettings,
  },
  {
    name: ClientNames.DemoCorp,
    code: ClientCodes.DemoCorp,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'democorp-portal.clientconnections.com',
        authHostname: 'democorp-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://democorp-login.clientconnections.com',
        homepageAnonymousUsersUrl: 'https://democorp.clientconnections.com',
      },
      {
        hostname: 'democorp-portal-dev.clientconnections.com',
        authHostname: 'democorp-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://democorp-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'democorp-portal-qa.clientconnections.com',
        authHostname: 'democorp-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://democorp-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'democorp-portal-uat.clientconnections.com',
        authHostname: 'democorp-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://democorp-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: DemoCorpSettings,
  },
  {
    name: ClientNames.CENOVUS,
    code: ClientCodes.CENOVUS,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'cenovus-portal.clientconnections.com',
        authHostname: 'cenovus-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://cenovus-login.clientconnections.com',
        homepageAnonymousUsersUrl: 'https://cenovus.clientconnections.com',
      },
      {
        hostname: 'cenovus-portal-dev.clientconnections.com',
        authHostname: 'cenovus-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://cenovus-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'cenovus-portal-qa.clientconnections.com',
        authHostname: 'cenovus-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://cenovus-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'cenovus-portal-uat.clientconnections.com',
        authHostname: 'cenovus-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://cenovus-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: CenovusSettings,
  },
  {
    name: ClientNames.KPMG,
    code: ClientCodes.KPMG,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'kpmg-portal.clientconnections.com',
        authHostname: 'kpmg-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://kpmg-login.clientconnections.com',
        homepageAnonymousUsersUrl: 'https://kpmg.clientconnections.com',
      },
      {
        hostname: 'kpmg-portal-dev.clientconnections.com',
        authHostname: 'kpmg-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://kpmg-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'kpmg-portal-qa.clientconnections.com',
        authHostname: 'kpmg-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://kpmg-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'kpmg-portal-uat.clientconnections.com',
        authHostname: 'kpmg-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://kpmg-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: KpmgSettings,
  },
  {
    name: ClientNames.HCMWorks,
    code: ClientCodes.HCMWorks,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'hcmworks-portal.clientconnections.com',
        authHostname: 'hcmworks-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://hcmworks-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'hcmworks-portal-dev.clientconnections.com',
        authHostname: 'hcmworks-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://hcmworks-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'hcmworks-portal-qa.clientconnections.com',
        authHostname: 'hcmworks-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://hcmworks-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'hcmworks-portal-uat.clientconnections.com',
        authHostname: 'hcmworks-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://hcmworks-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: HcmWorksSettings,
  },
  {
    name: ClientNames.ProcomTeam,
    code: ClientCodes.ProcomTeam,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'procominhouseteam-portal.clientconnections.com',
        authHostname: 'login.procomservices.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://procominhouseteam-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procominhouseteam.clientconnections.com/jobs',
      },
      {
        hostname: 'procominhouseteam-portal-dev.clientconnections.com',
        authHostname:
          'procominhouseteam-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://procominhouseteam-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'procominhouseteam-portal-qa.clientconnections.com',
        authHostname: 'procominhouseteam-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl:
          'https://procominhouseteam-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'procominhouseteam-portal-uat.clientconnections.com',
        authHostname:
          'procominhouseteam-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://procominhouseteam-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: ProcomTeamSettings,
  },
  {
    name: ClientNames.Flextrack,
    code: ClientCodes.Flextrack,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'flextrack-portal.clientconnections.com',
        authHostname: 'flextrack-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://flextrack-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://flextrack.clientconnections.com/jobs',
      },
      {
        hostname: 'flextrack-portal-dev.clientconnections.com',
        authHostname: 'flextrack-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://flextrack-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'flextrack-portal-qa.clientconnections.com',
        authHostname: 'flextrack-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl:
          'https://flextrack-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'flextrack-portal-uat.clientconnections.com',
        authHostname: 'flextrack-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://flextrack-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: FlextrackSettings,
  },
  {
    name: ClientNames.GorillaWorks,
    code: null,
    vendorCode: VendorCodes.PCGL,
    domains: [
      {
        hostname: 'portal.gorillaworks.io',
        authHostname: 'login.gorillaworks.io',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://login.gorillaworks.io',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'gorillaworks-portal-dev.clientconnections.com',
        authHostname: 'gorillaworks-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://gorillaworks-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'procom-portal-qa.clientconnections.com',
        authHostname: 'procom-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://procom-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'gorillaworks-portal-uat.clientconnections.com',
        authHostname: 'gorillaworks-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://gorillaworks-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: GorillaWorksSettings,
  },
  {
    name: ClientNames.DemoCorp,
    code: null,
    vendorCode: VendorCodes.TSLA,
    domains: [
      {
        hostname: 'demovendor-portal.clientconnections.com',
        authHostname: 'demovendor-portal-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://demovendor-portal-login.clientconnections.com',
        homepageAnonymousUsersUrl: 'https://demovendor.clientconnections.com',
      },
      {
        hostname: 'demovendor-portal-dev.clientconnections.com',
        authHostname: 'demovendor-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://demovendor-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'demovendor-portal-qa.clientconnections.com',
        authHostname: 'demovendor-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl:
          'https://demovendor-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'demovendor-portal-uat.clientconnections.com',
        authHostname: 'demovendor-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://demovendor-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
    ],
    settings: DemoCorpSettings,
  },
  {
    name: ClientNames.Symbiotic,
    code: null,
    vendorCode: VendorCodes.Symbiotic,
    domains: [
      {
        hostname: 'symbiotic-portal.clientconnections.com',
        authHostname: 'symbiotic-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://symbiotic-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'symbiotic-portal-dev.clientconnections.com',
        authHostname: 'symbiotic-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://symbiotic-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'symbiotic-portal-qa.clientconnections.com',
        authHostname: 'symbiotic-portal-qa-login.clientconnections.com',
        domainType: ClientDomainType.Qa,
        authPortalUrl:
          'https://symbiotic-portal-qa-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'symbiotic-portal-uat.clientconnections.com',
        authHostname: 'symbiotic-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://symbiotic-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: SymbioticSettings,
  },
  {
    name: ClientNames.Aplin,
    code: null,
    vendorCode: VendorCodes.APLN,
    domains: [
      {
        hostname: 'aplin.gorillaworks.io',
        authHostname: 'aplin-login.gorillaworks.io',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://aplin-login.gorillaworks.io',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'aplin-portal-dev.gorillaworks.io',
        authHostname: 'aplin-portal-dev-login.gorillaworks.io',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://aplin-portal-dev-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'aplin-portal-qa.gorillaworks.io',
        authHostname: 'aplin-portal-qa-login.gorillaworks.io',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://aplin-portal-qa-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'aplin-portal-uat.gorillaworks.io',
        authHostname: 'aplin-portal-uat-login.gorillaworks.io',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://aplin-portal-uat-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: AplinSettings,
  },
  {
    name: ClientNames.Compustaff,
    code: null,
    vendorCode: VendorCodes.CMPSTF,
    domains: [
      {
        hostname: 'compustaff.gorillaworks.io',
        authHostname: 'compustaff-login.gorillaworks.io',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://compustaff-login.gorillaworks.io',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'compustaff-portal-dev.gorillaworks.io',
        authHostname: 'compustaff-portal-dev-login.gorillaworks.io',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://compustaff-portal-dev-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'compustaff-portal-qa.gorillaworks.io',
        authHostname: 'compustaff-portal-qa-login.gorillaworks.io',
        domainType: ClientDomainType.Qa,
        authPortalUrl: 'https://compustaff-portal-qa-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'compustaff-portal-uat.gorillaworks.io',
        authHostname: 'compustaff-portal-uat-login.gorillaworks.io',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://compustaff-portal-uat-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: CompustaffSettings,
  },
  {
    name: ClientNames.Softchoice,
    code: null,
    vendorCode: VendorCodes.SFTCHE,
    domains: [
      {
        hostname: 'softchoice-portal.clientconnections.com',
        authHostname: 'softchoice-login.clientconnections.com',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://softchoice-login.clientconnections.com',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'softchoice-portal-dev.clientconnections.com',
        authHostname: 'softchoice-portal-dev-login.clientconnections.com',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://softchoice-portal-dev-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'softchoice-portal-uat.clientconnections.com',
        authHostname: 'softchoice-portal-uat-login.clientconnections.com',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://softchoice-portal-uat-login.clientconnections.com',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: SoftchoiceSettings,
  },
  {
    name: ClientNames.EastWest,
    code: null,
    vendorCode: VendorCodes.ESTWST,
    domains: [
      {
        hostname: 'eastweststaffing.gorillaworks.io',
        authHostname: 'eastweststaffing-login.gorillaworks.io',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://eastweststaffing-login.gorillaworks.io',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'eastweststaffing-portal-dev.gorillaworks.io',
        authHostname: 'eastweststaffing-portal-dev-login.gorillaworks.io',
        domainType: ClientDomainType.Develop,
        authPortalUrl:
          'https://eastweststaffing-portal-dev-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'eastweststaffing-portal-qa.gorillaworks.io',
        authHostname: 'eastweststaffing-portal-qa-login.gorillaworks.io',
        domainType: ClientDomainType.Qa,
        authPortalUrl:
          'https://eastweststaffing-portal-qa-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'eastweststaffing-portal-uat.gorillaworks.io',
        authHostname: 'eastweststaffing-portal-uat-login.gorillaworks.io',
        domainType: ClientDomainType.Uat,
        authPortalUrl:
          'https://eastweststaffing-portal-uat-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: EastWestSettings,
  },
  {
    name: ClientNames.VNDRHWN,
    code: null,
    vendorCode: VendorCodes.VNDRHWN,
    domains: [
      {
        hostname: 'vanderhouwen.gorillaworks.io',
        authHostname: 'vanderhouwen-login.gorillaworks.io',
        domainType: ClientDomainType.Production,
        authPortalUrl: 'https://vanderhouwen-login.gorillaworks.io',
        homepageAnonymousUsersUrl: null,
      },
      {
        hostname: 'vanderhouwen-portal-dev.gorillaworks.io',
        authHostname: 'vanderhouwen-portal-dev-login.gorillaworks.io',
        domainType: ClientDomainType.Develop,
        authPortalUrl: 'https://vanderhouwen-portal-dev-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
      {
        hostname: 'vanderhouwen-portal-uat.gorillaworks.io',
        authHostname: 'vanderhouwen-portal-uat-login.gorillaworks.io',
        domainType: ClientDomainType.Uat,
        authPortalUrl: 'https://vanderhouwen-portal-uat-login.gorillaworks.io',
        homepageAnonymousUsersUrl:
          'https://procom-portal-home.azurewebsites.net',
      },
    ],
    settings: VanderHouwenSettings,
  },
]
