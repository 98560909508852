export enum SignUpRoutes {
  SignUp = '/signup',
  SignUpEmail = '/signup-email',
}

export enum SocialLoginContext {
  Signup = 'signup',
  Login = 'login',
}

export enum LogoSizeOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
